( function( $ ) {

	$(document).ready(function(){


		$('#listing-page__filters').submit(function(e){

			var filter = $('#listing-page__filters');
				filter.find('input[name=page]').val(1);
			var listing_type = filter.find('input[name=listing_type]').val();
			$.ajax({
				url:filter.attr('action'),
				data:filter.serialize(), // form data
				type:filter.attr('method'), // POST
				beforeSend:function(xhr){
					filter.find('input[type=submit]').val(mxwes_strings.processing); // changing the button label
					$('#response').addClass('is-loading');
					$('.listing__loader').addClass('is-loading');
				},
				success:function(data){
					filter.find('input[type=submit]').val(mxwes_strings.apply_filter); // changing the button label back
					
					if(listing_type == 'pathways'){
						$('.listing--collections').addClass('is-searched');
						//console.log('pathways');
					}else if(listing_type == 'collections'){
						$('.listing--collections').removeClass('is-searched');
						//console.log('pathways');
					}

					$('#response').html(data).removeClass('is-loading'); // insert data
					$('.listing__loader').removeClass('is-loading');
					
					//$('#listing-page__filters button[type=reset]').prop('disabled', false);

					var new_max_pages = $('.listing_max_pages').attr('data-max-pages');
					filter.find('input[name=max_pages]').val(new_max_pages);
					if ( (filter.find('input[name=page]').val() == filter.find('input[name=max_pages]').val()) || (filter.find('input[name=max_pages]').val() == 0) ){
						$('#jw_loadmore').hide(); // if last page, HIDE the button

					}

					if((filter.find('input[name=page]').val() < filter.find('input[name=max_pages]').val()) && filter.find('input[name=max_pages]').val() != 0){
						$('#jw_loadmore').show();
					}

				}
			});
			return false;
		});

		$('#jw_loadmore').on('click', function(e) {
			e.preventDefault();

			var filter = $('#listing-page__filters');
			var current_page = filter.find('input[name=page]').val();
			var new_page = parseInt(current_page) + 1;
			filter.find('input[name=page]').val(new_page);
			var listing_type = filter.find('input[name=listing_type]').val();

			$.ajax({
				url:filter.attr('action'),
				data:filter.serialize(), // form data
				type:filter.attr('method'), // POST
				beforeSend:function(xhr){
					filter.find('input[type=submit]').val(mxwes_strings.processing); // changing the button label
					$('#jw_loadmore').html(mxwes_strings.processing);
					$('#response').addClass('is-loading');
					$('.listing__loader').addClass('is-loading');
				},
				success:function(data){
					filter.find('input[type=submit]').val(mxwes_strings.apply_filter); // changing the button label back
					$('#jw_loadmore').html(mxwes_strings.load_more+' <span class="icon-glyph_chevron_down"></span>');





					

					if(listing_type == 'support_hub'){
						$('#response .is-searched').append(data).removeClass('is-loading'); // insert data
						$('#response').removeClass('is-loading');
						$('.listing__loader').removeClass('is-loading');
					}else{
						$('#response').append(data).removeClass('is-loading'); // insert data
						$('.listing__loader').removeClass('is-loading');
					}



					if ( filter.find('input[name=page]').val() == filter.find('input[name=max_pages]').val() ){
						$('#jw_loadmore').hide(); // if last page, HIDE the button
					}

					if((filter.find('input[name=page]').val() < filter.find('input[name=max_pages]').val()) && filter.find('input[name=max_pages]').val() != 0){
						$('#jw_loadmore').show();
					}

					//$('.listing_max_pages').last().scrollIntoView();

					var scrollToItemNumber = (current_page*10)-1; //offset accounted for because we are using eq(), -1 for previous result
					var scrollItem = $('#response a').eq(scrollToItemNumber);

					if(listing_type == 'resources'){
						scrollToItemNumber = (current_page*12)-1; //offset accounted for because we are using eq(), -1 for previous result
						scrollItem = $('#response a').eq(scrollToItemNumber);
					}

					$("body,html").animate(
						{
							scrollTop: $(scrollItem).offset().top - 40
						},
						300 //speed
					);

				}
			});
			return false;
		});


		$('#listing-page__filters select').on('change', function(){
			//$('#listing-page__filters input[name=searched]').prop('value', 'true');
			$(this).closest('form').submit();
			$('#listing-page__filters button[type=reset]').prop('disabled', false);
		});

		$('#listing-page__filters input[type=checkbox]').on('change', function(){
			//$('#listing-page__filters input[name=searched]').prop('value', 'true');
			$(this).closest('form').submit();
			$('#listing-page__filters button[type=reset]').prop('disabled', false);
		});

		$('#listing-page__filters input[type=search]').on('change', function(){
			//$('#listing-page__filters input[name=searched]').prop('value', 'true');
			$(this).closest('form').submit();
			$('#listing-page__filters button[type=reset]').prop('disabled', false);
		});


		$('#listing-page__filters button[type=reset]').on('click', function(e){
		   //$(this).closest('form').submit();
			e.preventDefault();
			var listing_type = $('#listing-page__filters').find('input[name=listing_type]').val();

			$(':input','#listing-page__filters')
				.not(':button, :submit, :reset, :hidden, #list_sorting, [type=checkbox], [type=radio]')
				.val('')
				.removeAttr('checked')
				.removeAttr('selected');

			$('input[type=radio]').removeAttr('checked');
			$('input[type=checkbox]').removeAttr('checked');

			

			$('#listing-page__filters input[name=searched]').prop('value', 'false');
			var defaultSort = $('#list_sorting').attr('data-default-sort');
			$('#list_sorting option[value='+defaultSort+']').prop('selected', true);
			//var minScreenSize = $('#listing-page__filters input[name=min_screen_size]').attr('min');
			//var maxScreenSize = $('#listing-page__filters input[name=max_screen_size]').attr('max');
			//$('#listing-page__filters input[name=min_screen_size]').val(minScreenSize);
			//$('#listing-page__filters input[name=max_screen_size]').val(maxScreenSize);
			
		   
			$(this).prop('disabled', true);
			//console.log(listing_type);
			if(listing_type == 'pathways'){
				$('#listing-page__filters').find('input[name=listing_type]').val('collections');
				$(this).closest('form').submit();
				$('#listing-page__filters').find('input[name=listing_type]').val('pathways');				
			}else{
				$(this).closest('form').submit();
			}
			
		});
	});

} )( jQuery );

