( function( $ ) {

	$(document).ready(function(){
		
		$('.gallery-display').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			asNavFor: '.gallery-nav',
			adaptiveHeight: true
		});
		$('.gallery-nav').slick({
			slidesToShow: 5,
			slidesToScroll: 5,
			asNavFor: '.gallery-display',
			dots: true,
			//infinite: false,
			centerMode: true,
			focusOnSelect: true
		});
		
		
	$('.block--small-image-grid.block-style--rotator.block-image-style--framed .item-list').slick({
		  //centerMode: true,
		  //centerPadding: '60px',
		  arrows: true,
		  slidesToShow: 5,
		  /*
		  responsive: [
			{
			  breakpoint: 768,
			  settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '40px',
				slidesToShow: 3
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '40px',
				slidesToShow: 1
			  }
			}
		  ]
		  */
		});
		
		$('.block--testimonials.block-style--rotator .item-list').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			adaptiveHeight: true,
			//centerMode: true,
			//centerPadding: '0px',
		});
		
		$('.accordion-item__header').on('click', function(){
			$(this).toggleClass('is-open').next('.accordion-item__content').slideToggle();
		});
		
		$('#page__sharing__toggle').on('click', function(e){
			e.preventDefault();
			var container = $('.page__sharing');
	
			if(container.hasClass('is-active')){
				container.removeClass('is-active');
			}else{
				container.addClass('is-active');
			}
		});
		
		$('#page_sharing_close').on('click', function(e){
			e.preventDefault();
			$('#page__sharing__toggle').click();
		});
		
		
		$('.bst-language-switcher__active-option-toggle').on('click', function(e){
			e.preventDefault();
			
			var langSwitchContainer = $(this).parent('.bst-language-switcher');
			
			if($(langSwitchContainer).hasClass('is-open')){
				$(langSwitchContainer).removeClass('is-open');
			}else{
				$(langSwitchContainer).addClass('is-open');
			}
		});
		
		
		$('.bst-language-switcher a').on('focusout', function(e){
					
			if($(this).parent('.bst-language-switcher').length == 0){
				$('.bst-language-switcher').removeClass('is-open');
			}
		});
		
		
		


	});
	

	
	document.addEventListener('keydown', function(event) {
		const key = event.key; // Or const {key} = event; in ES6+
			//console.log(key);
		if (key === "Escape") {
			$('.bst-language-switcher').removeClass('is-open');
		}
	});
	
	
	$(".embed-container").fitVids();
	
	$('#offCanvas').offcanvas({
		modifiers: 'right, overlay', // default options
		triggerButton: '#triggerButton' // btn to open offcanvas
	});
	
	var primary_nav_off_canvas = $('#offCanvas').data('offcanvas-component');
	
	$('#offcanvas--main-menu__close').on('click', function(e){
		e.preventDefault();
		primary_nav_off_canvas.close();
	});
	

	
	//$.get('https://grow.farmcommons.org', function(){ /*callback*/ })
	
	
	// Hide Variation Form if variation availability is set to "Contact Us"
	$('body').on('change.wc-variation-form', function(){
		//var variation_id = $('input.variation_id').val();
		
		if($('.single_variation_wrap .woocommerce-variation-availability p.stock').hasClass('contact-us')){
			$('.single_variation').trigger('hide_variation');
		}
		
	});


} )( jQuery );
